/* swiper
------------------------------------------------------------------------------*/

@import 'swiper/swiper';
@import 'swiper/modules/navigation';

.swiper-button-prev,
.swiper-button-next {
	background-color: rgba($white, 0.75);
	border-radius: 9999px;
	height: 20px;
	margin-top: -10px;
	width: 20px;
	&::after {
		color: $black;
		font-size: 10px;
	}
}