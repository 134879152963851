/* -----------------------------------------------------------------------------

Main Stylesheet

Company: acoda
Author: Arron Coda

----------------------------------------------------------------------------- */

// abstract
//------------------------------------------------------------------------------

@import 'abstracts/variables';
// @import 'abstracts/functions';
// @import 'abstracts/placeholders';
// @import 'abstracts/mixins';

// base
//------------------------------------------------------------------------------

@import 'base/normalize';
@import 'base/global';

// elements
//------------------------------------------------------------------------------

// @import 'elements/icons';
// @import 'elements/buttons';
// @import 'elements/forms';

// components
//------------------------------------------------------------------------------

@import 'components/swiper';

// layout
//------------------------------------------------------------------------------

@import 'layout/header';
@import 'layout/footer';

// modules
//------------------------------------------------------------------------------

@import 'modules/hero-fader';
@import 'modules/intro';
@import 'modules/slider';


/* END----------------------------------------------------------------------- */