/* hero fader
------------------------------------------------------------------------------*/

.hero-fader {
	color: $white;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	height: 100vh;
	max-height: 1024px;
	&__fg,
	&__bg {
		grid-column: 1 / 2;
		grid-row: 1 / 2;
		position: relative;
	}
	&__bg {
		&::after {
			background: linear-gradient(0deg, rgba($black, 0.67) 0%, rgba($black, 0.3) 100%);
			bottom: 0;
			content: '';
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
		}
	}
	&__ctr {
		align-items: center;
		height: 100%;
		display: flex;
		justify-content: center;
	}
	&__slide {
		height: 100%;
		left: 0;
		opacity: 0;
		position: absolute;
		top: 0;
		transition: opacity 1s ease-in-out;
		width: 100%;
		&.--active {
			opacity: 1;
		}
	}
	&__img {
		display: block;
		height: 100%;
		object-fit: cover;
		width: 100%;
	}
	&__title {
		font-family: $font-heading;
		font-size: 4.8rem;
		margin: 80px 0;
		text-align: center;
		span {
			display: block;
			font-family: $font-content;
			font-size: 1.8rem;
			letter-spacing: 6px;
			text-transform: uppercase;
		}
	}
	&__scroll {
		bottom: 40px;
		display: block;
		height: 50px;
		left: 50%;
		margin-left: -25px;
		position: absolute;
		transition: transform 300ms;
		width: 50px;
		img {
			display: block;
		}
		&:hover {
			transform: scale(1.2);
		}
	}
	@media(min-width: $mob-xl){
		&__title {
			font-size: 7.2rem;
		}
	}
	@media(min-width: $tab-lg){
		&__title {
			font-size: 15rem;
			span {
				font-size: 2.4rem;
			}
		}
	}
}