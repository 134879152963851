// variables
//------------------------------------------------------------------------------

// colours
//------------------------------------------------------------------------------

$white:					#ffffff;
$black:					#000000;
$off-white:			#f0f0f0;
$dusty-gray:		#989898;

$brand-pearl: #FCF5DF;
$brand-orange: #ECA940;
$brand-green: #617B69;

$brand-error: #dd4d4d;

// fonts
//------------------------------------------------------------------------------

$font-content: futura-pt, sans-serif;
$font-heading: garamond-premier-pro-display, serif;

// breakpoints
//------------------------------------------------------------------------------

$mob-sm: 320px;
$mob-md: 360px;
$mob-lg: 480px;
$mob-xl: 512px;
$tab-sm: 768px;
$tab-lg: 1024px;
$desk-xs: 1280px;
$desk-sm: 1440px;
$desk-md: 1680px;
$desk-lg: 1920px;

$mob-sm-min: $mob-sm;
$mob-md-min: $mob-md;
$mob-lg-min: $mob-lg;
$mob-xl-min: $mob-xl;
$tab-sm-min: $tab-sm;
$tab-lg-min: $tab-lg;
$desk-xs-min: $desk-xs;
$desk-sm-min: $desk-sm;
$desk-md-min: $desk-md;
$desk-lg-min: $desk-lg;

$mob-sm-max: ($mob-sm-min - 1);
$mob-md-max: ($mob-md-min - 1);
$mob-lg-max: ($mob-lg-min - 1);
$mob-xl-max: ($mob-xl-min - 1);
$tab-sm-max: ($tab-sm-min - 1);
$tab-lg-max: ($tab-lg-min - 1);
$desk-xs-max: ($desk-xs-min - 1);
$desk-sm-max: ($desk-sm-min - 1);
$desk-md-max: ($desk-md-min - 1);
$desk-lg-max: ($desk-lg-min - 1);

// constraints
//------------------------------------------------------------------------------

$page-max: $desk-lg;
$ctr-max: $desk-sm;
$col-max: 840px;

$header-height: 80px;
$header-height-pinned: 60px;

// END -------------------------------------------------------------------------