/* global
------------------------------------------------------------------------------*/

html {
	box-sizing: border-box;
	font-size: 62.5%;
	height: 100%;
	scroll-behavior: smooth;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	background-color: $off-white;
	color: $brand-green;
	font-family: $font-content;
	font-size: 1.6rem;
	font-weight: 300;
	line-height: 1.5;
	@media(min-width: $desk-md){
		font-size: 1.8rem;
	}
}

.page {
	background-color: $white;
	box-shadow: 0 0 15px 0 rgba($black, 0.03);
	margin: 0 auto;
	max-width: $page-max;
	position: relative;
}

.ctr {
	margin: 0 auto;
	padding-left: 20px;
	padding-right: 20px;
	@media(min-width: $tab-sm){
		padding-left: 40px;
		padding-right: 40px;
	}
	@media(min-width: $desk-xs){
		padding-left: 60px;
		padding-right: 60px;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-heading;
	font-weight: 300;
	line-height: 1.25;
	margin-bottom: 1em;
	margin-top: 0;
}

p {
	margin-bottom: 1em;
	margin-top: 0;
	&:last-child {
		margin-bottom: 0;
	}
}

a {
	color: currentColor;
}

ul {
	padding-left: 1em;
}

img {
	display: block;
	max-width: 100%;
}

address {
	font-style: normal;
}