/* header
------------------------------------------------------------------------------*/

.header {
	background-color: transparent;
	left: auto;
	max-width: $page-max;
	position: fixed;
	right: auto;
	top: 0;
	transition: background-color 300ms;
	width: 100%;
	z-index: 2;
	&__ctr {
		align-items: center;
		height: $header-height;
		display: flex;
		justify-content: space-between;
		transition: height 300ms;
	}
	&__logo {
		height: 12px;
		transition: height 300ms;
		width: auto;
	}
	@media(min-width: $tab-sm){
		&__logo {
			height: 15px;
		}
	}
}

.--header-pinned .header {
	background-color: rgba($brand-green, 0.8);
	&__ctr {
		height: $header-height-pinned;
	}
  &__logo {
		height: 10px;
	}
	@media(min-width: $tab-sm){
		&__logo {
			height: 12px;
		}
	}
}