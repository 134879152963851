/* slider
------------------------------------------------------------------------------*/

.slider {
	padding-bottom: 40px;
}

.slide {
	aspect-ratio: 1 / 1;
	color: $white;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	overflow: hidden;
	&__bg,
	&__fg {
		grid-column: 1 / 2;
		grid-row: 1 / 2;
		position: relative;
	}
	&__bg {
		transition: transform 300ms ease-in-out;
		&::after {
			background: linear-gradient(0deg, rgba($black, 0.5) 0%, rgba($black, 0.1) 100%);
			bottom: 0;
			content: '';
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
		}
	}
	&:hover &__bg {
		transform: scale(1.1);
	}
	&__fg {
		align-items: flex-end;
		display: flex;
		padding: 10%;
	}
	&__img {
		height: 100%;
		object-fit: cover;
		width: 100%;
	}
	&__link {
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		text-indent: -9999px;
		top: 0;
	}
	&__title {
		font-size: 2.8rem;
		margin-bottom: 0;
	}
	&__loc {
		font-size: 1.6rem;
	}
	@media(min-width: $desk-xs){
		&__title {
			font-size: 3.2rem;
		}
		&__loc {
			font-size: 1.8rem;
		}
	}
}