/* intro
------------------------------------------------------------------------------*/

.intro {
	padding: 40px 0;
	text-align: center;
	&__copy {
		font-family: $font-heading;
		font-size: 2.0rem;
		font-weight: 300;
		margin: 0 auto;
		max-width: $col-max;
	}
	@media(min-width: $tab-lg){
		padding: 80px 0;
		&__copy {
			font-size: 2.8rem;
		}
	}
}