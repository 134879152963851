/* footer
------------------------------------------------------------------------------*/

.footer {
	padding: 40px 0;
	background-color: $brand-green;
	color: $white;
	text-align: center;
	&__grid {
		display: grid;
		justify-items: center;
	}
	&__col {
		margin-bottom: 32px;
		&.-legal {
			margin-bottom: 0;
		}
	}
	&__logo {
		width: 100%;
		margin: 0 auto;
		max-width: 250px;
	}
	&__nav {
		align-items: center;
		display: inline-flex;
		flex-direction: column;
		&.-contact {
			margin-bottom: 24px;
		}
	}
	&__title {
		font-family: $font-content;
		font-size: 20px;
		margin-bottom: 0.25em;
	}
	&__subtitle {
		font-family: $font-content;
		font-size: 20px;
		margin-bottom: 0.25em;
	}
	&__link {
		background-image: linear-gradient(0deg, currentColor 0, currentColor);
		background-position: bottom 0 left -1px;
		background-repeat: no-repeat;
		background-size: 1px 1px;
		text-decoration: none;
		transition: background-size 300ms;
		&.-map {
			font-weight: 400;
			position: relative;
			margin-right: 20px;
			svg {
				display: block;
				fill: currentColor;
				height: auto;
				position: absolute;
				right: -20px;
				top: 2px;
				width: 18px;
			}
		}
		&:hover {
			background-position: bottom 0 left 0;
			background-size: 100% 1px;
			text-decoration: none;
		}
	}
	&__address {
		margin-bottom: 10px;
	}
	&__accreditations {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: flex-end;
		img {
			display: block;
			margin: 10px;
		}
	}
	&__legal {
		font-size: 16px;
		margin-bottom: 0.25em;
		text-align: center;
		&.-small {
			opacity: 0.75;
			font-size: 12px;
		}
	}
	@media(min-width: $tab-sm){
		&__grid {
			grid-template-columns: repeat(3, 1fr);
		}
		&__col {
			&.-logo,
			&.-accred,
			&.-legal {
				grid-column: 1 / -1;
			}
		}
	}
	@media(min-width: $tab-lg){
		padding-top: 60px;
		text-align: left;

		&__grid {
			grid-template-columns: repeat(4, 1fr);
		}
		&__col {
			margin-bottom: 64px;
			&.-logo {
				grid-column: auto;
			}
			&.-accred {
				margin-bottom: 32px;
			}
		}
		&__logo {
			margin-top: 42px;
			max-width: 180px;
		}
		&__nav {
			align-items: start;

		}
		&__social {
			justify-content: start;
		}
		&__title {
			font-size: 2.8rem;
			&.-accred {
				display: none;
			}
		}
		&__subtitle {
			font-size: 2.2;
		}
	}
	@media(min-width: $desk-xs){
		&__logo {
			max-width: 250px;
		}
	}
}